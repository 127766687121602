import type { ImageField, RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const WhatWeDoSectionSliceType = 'what_we_do_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title', 'subtitle', 'image'] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  title: string;
  subtitle: RichTextField;
  image: ImageField;
};

export type WhatWeDoSectionSliceResponse = PrismicSlice<
  typeof WhatWeDoSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
