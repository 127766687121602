import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const WarningIcon = (props: IconProps) => (
  <IconBase viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6467 21.7702H4.32671C3.79195 21.7655 3.26755 21.6222 2.8048 21.3541C2.34205 21.086 1.9568 20.7025 1.68671 20.2409C1.41662 19.7793 1.27095 19.2556 1.26394 18.7208C1.25693 18.1861 1.38882 17.6587 1.64671 17.1902L9.30671 3.7502C9.57314 3.27431 9.9623 2.87857 10.4337 2.60419C10.905 2.32982 11.4413 2.18686 11.9867 2.1902C12.5331 2.18146 13.0716 2.32215 13.5439 2.59708C14.0162 2.87201 14.4045 3.27073 14.6667 3.7502L22.3267 17.1902C22.5944 17.6598 22.7341 18.1915 22.7321 18.732C22.73 19.2726 22.5862 19.8031 22.3149 20.2707C22.0437 20.7383 21.6546 21.1265 21.1863 21.3967C20.7181 21.6668 20.1873 21.8094 19.6467 21.8102V21.7702ZM11.9867 3.6902C11.7064 3.68915 11.4309 3.76269 11.1883 3.90327C10.9458 4.04386 10.7451 4.24643 10.6067 4.4902L2.94671 17.8902C2.80827 18.132 2.73581 18.4059 2.73657 18.6846C2.73733 18.9632 2.8113 19.2367 2.95106 19.4778C3.09083 19.7188 3.29147 19.9189 3.53291 20.058C3.77434 20.197 4.04808 20.2702 4.32671 20.2702H19.6467C19.9253 20.2702 20.1991 20.197 20.4405 20.058C20.6819 19.9189 20.8826 19.7188 21.0224 19.4778C21.1621 19.2367 21.2361 18.9632 21.2368 18.6846C21.2376 18.4059 21.1651 18.132 21.0267 17.8902L13.3667 4.4902C13.2283 4.24643 13.0276 4.04386 12.7851 3.90327C12.5426 3.76269 12.267 3.68915 11.9867 3.6902ZM11.998 8.62988C12.4123 8.62988 12.748 8.96567 12.748 9.37988V13.1199C12.748 13.5341 12.4123 13.8699 11.998 13.8699C11.5838 13.8699 11.248 13.5341 11.248 13.1199V9.37988C11.248 8.96567 11.5838 8.62988 11.998 8.62988ZM11.998 17.5C12.5503 17.5 12.998 17.0523 12.998 16.5C12.998 15.9477 12.5503 15.5 11.998 15.5C11.4458 15.5 10.998 15.9477 10.998 16.5C10.998 17.0523 11.4458 17.5 11.998 17.5Z"
      fill="currentColor"
    />
  </IconBase>
);

export default WarningIcon;
