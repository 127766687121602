import styled from 'styled-components';

import { WhatWeDoItem } from '@yoweb/ui/components/sections/WhatWeDoSection/WhatWeDoItem';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { pxToRem, media } from '@yoweb/ui/styles/utils';

type WhatWeDoSectionProps = {
  title: string;
  subtitle: ReactNode;
  imageSrc: string;
};

export const WhatWeDoSection = ({ title, subtitle, imageSrc }: WhatWeDoSectionProps) => (
  <SectionWrapper>
    <WhatWeDoItem index={1} title={title} text={subtitle} src={imageSrc} withBorderBottom={false} />
  </SectionWrapper>
);

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: ${pxToRem(840)};

  ${media._`
    margin: ${getSpace('normal1')} ${getSpace('normal3')} ${getSpace('normal1')} ${getSpace(
      'normal3',
    )}
  `}
  ${media.md`
    margin: ${getSpace('normal3')} ${getSpace('xl2')} ${getSpace('normal3')} ${getSpace('xl2')}
  `}
  ${media.lg`
    margin: ${getSpace('normal3')} auto ${getSpace('normal3')};
  `}
`;
