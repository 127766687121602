import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const WebinarSectionSliceType = 'webinar_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title', 'ctaHref', 'ctaLabel'] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  title: string;
  subtitle?: string;
  ctaHref: string;
  ctaLabel: string;
};

export type WebinarSectionSliceResponse = PrismicSlice<
  typeof WebinarSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
