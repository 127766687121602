import type { SelectField, FilledLinkToWebField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const LargeVideoSectionSliceType = 'large_video_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title', 'videoUrl'] as const; // Array of all required primary fields.

type Primary = {
  title: string;
  description?: string;
  videoUrl: FilledLinkToWebField;
  videoMobileUrl: FilledLinkToWebField;
  backgroundColor?: SelectField;
  navId?: string;
};

export type LargeVideoSectionSliceResponse = PrismicSlice<
  typeof LargeVideoSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
