import { type ReactNode, useEffect, useRef, useState } from 'react';
import styled, { type Colors, css, type Radii } from 'styled-components';
import Image, { type ImageProps } from 'next/legacy/image';

import { Grid } from '@yoweb/ui/components/Grid';
import { Text } from '@yoweb/ui/components/typography/Text';
import { getColor, getRadii, getSpace } from '@yoweb/ui/styles/utils/theme';
import { type BoxValue, setBoxValue } from '@yoweb/ui/styles/utils/mixins.utils';
import { ButtonLink } from '@yoweb/ui/components/buttons';
import CloseIcon from '@yoweb/ui/components/Icon/CloseIcon';
import { Flex } from '@yoweb/ui/components/Flex';
import { pxToRem } from '@yoweb/ui/styles/utils';
import useEventListener from '@yoweb/ui/hooks/useEventListener';

export type BannerProps = {
  // array of button elements or button components
  actions?: JSX.Element[];
  className?: string;
  icon?: JSX.Element;
  image?: ImageProps;
  subtitle?: ReactNode;
  title?: string;
  backgroundColor?: keyof Colors;
  borderColor?: keyof Colors;
  borderRadii?: keyof Radii;
  py?: BoxValue;
  px?: BoxValue;
  dismissible?: boolean;
  onDismiss?(): void;
  id?: string;
  alwaysCenterIcon?: boolean;
  compact?: boolean;
} & DataTestId;

const BANNER_COMPACT_BREAKPOINT = 768;

const defaultImageProps: Partial<ImageProps> = {
  height: 32,
  width: 32,
};

export const Banner = ({
  actions,
  className,
  icon,
  image,
  subtitle,
  title,
  backgroundColor,
  py,
  px,
  dismissible = false,
  borderColor = 'base100',
  onDismiss,
  id,
  alwaysCenterIcon,
  borderRadii,
  compact,
  ...props
}: BannerProps) => {
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const hasIcon = Boolean(icon || image);
  const containerTestId = props['data-testid'] || 'banner';
  const [isCompact, setCompact] = useState(compact ?? false);

  const calculateCompact = () => {
    if (!bannerRef.current) {
      return;
    }

    setCompact(bannerRef.current.getBoundingClientRect().width < BANNER_COMPACT_BREAKPOINT);
  };

  useEventListener('resize', calculateCompact);

  useEffect(() => calculateCompact(), []);

  const [isDismissed, setIsDismissed] = useState(false);

  const handleDismissBanner = () => {
    setIsDismissed(true);
    onDismiss?.();
  };

  return (
    <BannerContainer
      className={className}
      borderColor={borderColor}
      data-testid={containerTestId}
      hasIcon={hasIcon}
      backgroundColor={backgroundColor}
      py={py}
      px={px}
      isDismissed={isDismissed}
      ref={bannerRef}
      id={id}
      borderRadii={borderRadii}
    >
      <Flex justifyContent="flex-start">
        {hasIcon &&
          (image ? (
            <ImageWrap data-testid="banner-image">
              <Image {...{ ...defaultImageProps, ...image }} />
            </ImageWrap>
          ) : (
            <IconWrap isCentered={alwaysCenterIcon || !isCompact} data-testid="banner-icon">
              {icon}
            </IconWrap>
          ))}
        <Flex
          flex={1}
          columnGap="small3"
          style={{ position: 'relative' }}
          flexDirection={isCompact ? 'column' : 'row'}
        >
          <Flex
            flex={1}
            columnGap="small3"
            flexWrap="wrap"
            flexDirection={isCompact ? 'column' : 'row'}
            alignItems={isCompact ? 'flex-start' : 'center'}
          >
            {title && (
              <Text lineHeight="interactiveMd" size="md" weight="bold" data-testid="banner-title">
                {title}
              </Text>
            )}
            {subtitle && (
              <Text size="md" data-testid="banner-subtitle" as="div">
                {subtitle}
              </Text>
            )}
          </Flex>
          {actions && (
            <ActionsWrapper data-testid="banner-actions" isCompact={isCompact}>
              <Grid justify="start" columns={`repeat(${actions.length}, auto)`} gap="small3">
                {actions}
              </Grid>
            </ActionsWrapper>
          )}
        </Flex>
        {dismissible && (
          <DismissContainer>
            <ButtonLink
              onClick={handleDismissBanner}
              type="button"
              style={{ height: pxToRem(16), width: pxToRem(16) }}
              data-testid="banner-dismiss-button"
            >
              <CloseIcon />
            </ButtonLink>
          </DismissContainer>
        )}
      </Flex>
    </BannerContainer>
  );
};

const BannerContainer = styled.div<{
  hasIcon: boolean;
  backgroundColor?: keyof Colors;
  borderColor: keyof Colors;
  borderRadii?: keyof Radii;
  py?: BoxValue;
  px?: BoxValue;
  isDismissed?: boolean;
}>`
  background-color: ${({ backgroundColor = 'base000' }) => getColor(backgroundColor)};
  border-radius: ${({ borderRadii = 'medium' }) => getRadii(borderRadii)};
  border: 1px solid ${({ borderColor }) => getColor(borderColor)};
  display: ${({ isDismissed }) => (isDismissed ? 'none' : 'block')};
  padding: ${({ py = 'normal1', px = 'normal1' }) => css`
    ${setBoxValue(py)} ${setBoxValue(px)}
  `};
  position: relative;
  text-align: left;
`;

const IconWrap = styled.div<{ isCentered: boolean }>`
  align-self: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
  height: 24px;
  margin-right: ${getSpace('small4')};
  width: 24px;
`;

const ImageWrap = styled.div`
  align-self: flex-start;
  border-radius: ${getRadii('tiny')};
  height: 32px;
  overflow: hidden;
  margin-right: ${getSpace('small4')};
  width: 32px;
`;

const ActionsWrapper = styled.div<{ isCompact: boolean }>`
  margin-left: auto;

  ${({ isCompact }) =>
    isCompact &&
    css`
      margin-left: 0;
      margin-top: ${getSpace('normal1')};
    `}
`;

const DismissContainer = styled.div`
  margin-left: ${getSpace('normal1')};
  align-self: center;
  margin-top: ${pxToRem(3)};
`;
