export enum MediaStates {
  LOADING,
  ERROR,
  READY,
  PLAYING,
  PAUSED,
  ENDED,
}

export const STARTABLE_MEDIA_STATES = Object.values([
  MediaStates.READY,
  MediaStates.PAUSED,
  MediaStates.ENDED,
]);

export const PLAYER_LOADED_MEDIA_STATES = Object.values([
  ...STARTABLE_MEDIA_STATES,
  MediaStates.PLAYING,
]);
