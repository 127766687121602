import { useEffect, useState } from 'react';
import styled, { type Colors } from 'styled-components';
import Link from 'next/link';

import { useTranslation, Trans } from '@yoweb/next-i18next';
import { Container } from '@yoweb/ui/components/containers/Container';
import { Lottie } from '@yoweb/ui/components/Lottie';
import { InView, StaggerElements } from '@yoweb/ui/components/AnimateIn';
import { Button } from '@yoweb/ui/components/buttons';
import { ContainerWideColor } from '@yoweb/ui/components/marketing/ContainerWideColor';
import { Title, Text } from '@yoweb/ui/components/typography';
import { breakpoints, media, pxToRem } from '@yoweb/ui/styles/utils';
import { getSpace, th, getFontSize } from '@yoweb/ui/styles/utils/theme';
import { Flex } from '@yoweb/ui/components/Flex';
import { CircleCheckIcon } from '@yoweb/ui/components/Icon';
import { Box } from '@yoweb/ui/components/Box';
import type { PricingProps } from '@yoweb/store/utils/discount';
import { PricingDisclaimer } from '@yoweb/ui/components/marketing/PricingDisclaimer';
import { RenderOn } from '@yoweb/ui/components/RenderOn/RenderOn';

export type PricingModuleSectionProps = {
  subtitle: string;
  ctaText: string;
  ctaLink: string;
  bgColor: keyof Colors;
  animation?: 'juggling-a-lot' | 'wellness-pros-animation-data';
  bulletPoints: string[];
  disclaimerLink: string;
} & PricingProps;

const animationMap = {
  'juggling-a-lot': () => import('@yoweb/ui/lottieAnimations/juggling-a-lot.json'),
  'wellness-pros-animation-data': () =>
    import('@yoweb/ui/lottieAnimations/wellness-pros-animation-data.json'),
};

export const PricingModuleSection = (props: PricingModuleSectionProps) => {
  const {
    subtitle,
    ctaText,
    ctaLink,
    bgColor,
    animation = 'juggling-a-lot',
    bulletPoints,
    basePrice,
    discountPrice,
    initialOfferPriceFormatted,
  } = props;
  const [lottieState, setLottieState] = useState<unknown>(undefined);

  useEffect(() => {
    const setupLottie = async () => {
      const lottieData = await animationMap[animation]();
      setLottieState(lottieData as unknown);
    };

    void setupLottie();
  }, [animation, setLottieState]);

  const { t } = useTranslation('pricing');

  return (
    <>
      <InView>
        {(isInView) => (
          <ColoredSection bgColor={bgColor} data-testid="pricing-module-section">
            <ColoredContainer withGrid>
              <ColoredSectionContent data-testid="animated-colored-section">
                <StaggerElements isInView={isInView}>
                  <TextContent data-testid="pricing-module-section-header">
                    <Box mb="normal1">
                      <Title size={{ _: 'md', lg: 'xl' }} whiteSpace="pre-wrap">
                        <Trans
                          t={t}
                          i18nKey="home-module.heading"
                          values={{
                            price: initialOfferPriceFormatted ?? discountPrice ?? basePrice,
                          }}
                        />
                      </Title>
                      <TermsText>{subtitle.toUpperCase()}</TermsText>
                    </Box>
                    <Box
                      display="grid"
                      justifyItems="flex-start"
                      mx={{ _: 'medium1', md: 'none' }}
                      mb="small3"
                      rowGap={{ _: 'small3', md: 'normal1' }}
                    >
                      {bulletPoints &&
                        bulletPoints.length > 0 &&
                        bulletPoints.map((item, i) => (
                          <div key={`${item}-${i}`}>
                            <Flex display="inline-flex" alignItems="flex-start" gap="small3">
                              <RenderOn.TabletAndDesktop>
                                <CircleCheckIcon style={{ alignSelf: 'flex-start' }} size={32} />
                              </RenderOn.TabletAndDesktop>
                              <RenderOn.MobileOnly>
                                <CircleCheckIcon style={{ alignSelf: 'flex-start' }} size={24} />
                              </RenderOn.MobileOnly>
                              <CheckmarkText>{item}</CheckmarkText>
                            </Flex>
                          </div>
                        ))}
                    </Box>
                    <Box mt="normal1">
                      <Link href={ctaLink} passHref legacyBehavior>
                        <Button as="a" size={{ _: 'md', lg: 'lg' }}>
                          {ctaText}
                        </Button>
                      </Link>
                    </Box>
                  </TextContent>
                </StaggerElements>
              </ColoredSectionContent>
              <ColoredSectionImage>
                <Lottie animationData={lottieState} />
              </ColoredSectionImage>
            </ColoredContainer>
          </ColoredSection>
        )}
      </InView>
      <TermsContainer bgColor={bgColor}>
        <Terms data-testid="pricing-module-section-terms">
          <PricingDisclaimer {...props} />
        </Terms>
      </TermsContainer>
    </>
  );
};

const ColoredSection = styled(ContainerWideColor)`
  && {
    margin-bottom: ${pxToRem(-20)};
    ${media.md`
      margin-bottom: ${getSpace('normal3')};
    `}
  }
`;

const ColoredContainer = styled(Container)`
  && {
    padding: ${pxToRem(65)} 0 ${pxToRem(156)};
    row-gap: ${getSpace('normal3')};
    ${media.md`
      padding: ${pxToRem(125)} 0 ${pxToRem(156)};
    `}

    @media screen and (max-width: ${breakpoints.md}px) {
      padding-bottom: 2rem;
    }
  }
`;

const ColoredSectionContent = styled.div`
  align-content: center;
  display: grid;
  grid-column: 1 / span 12;
  text-align: center;
  ${media.md`
    grid-column: 1 / span 5;
    text-align: left;
  `}
  ${media.lg`
    grid-column: 2 / span 5;
  `}
`;

const TextContent = styled.div`
  display: grid;
  justify-items: center;
  row-gap: ${getSpace('small3')};
  ${media.md`
    row-gap: ${getSpace('normal1')};
    justify-items: flex-start;
  `}
`;

const ColoredSectionImage = styled.div`
  grid-column: 1 / span 12;
  grid-row: 1;
  mix-blend-mode: darken;
  position: relative;
  overflow: hidden;
  height: ${pxToRem(263)};

  svg {
    position: absolute;
    transform: translate3d(0, 0, 0) scale(1.5) !important;
  }
  ${media.md`
    grid-column: 6 / span 6;
  `}
  ${media.lg`
    height: 100vh;
    max-height: ${pxToRem(422)};
    grid-column: 7 / span 6;
    svg {
      position: absolute;
      transform: translate3d(0, 0, 0) scale(1.8) !important;
    }
  `}
`;

const CheckmarkText = styled(Text)`
  font-size: ${getFontSize('titleXs')};
  max-width: ${pxToRem(300)};
  text-align: left;

  ${media.md`
    font-size: ${getFontSize('titleSm')};
  `}
`;

const TermsText = styled(Text)`
  font-weight: 500;
  letter-spacing: 2.64px;
  font-size: 0.75rem;
  margin-top: 10px;
`;

const Terms = styled(Text)`
  line-height: 20px;
`;

const TermsContainer = styled(Container)<{ bgColor: keyof Colors }>`
  @media screen and (max-width: ${breakpoints.md}px) {
    margin: 0;
    padding: 2rem;
    text-align: center;
    min-width: 100vw;
    background-color: ${({ bgColor }) => th.getColor(bgColor)};
  }
`;
