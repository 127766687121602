import type { FilledLinkToWebField, ImageField } from '@prismicio/types';
import type { Colors } from 'styled-components';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export type UIProps = {
  disclaimerLink: string;
};

export const PricingModuleSliceType = 'pricing_module_slice';

// Required fields in slice
export const requiredPrimaryFields = [
  'subtitle',
  'ctaText',
  'ctaLink',
  'bgColor',
  'animation',
  'navId',
] as const;
export const requiredItemsFields = ['description'] as const;

type Primary = {
  subtitle: string;
  ctaText: string;
  ctaLink: FilledLinkToWebField;
  bgColor: keyof Colors;
  animation: 'juggling-a-lot' | 'wellness-pros-animation-data';
  navId: string;
};

type Item = {
  icon: ImageField;
  description: string;
};

export type PricingModuleSliceResponse = PrismicSlice<
  typeof PricingModuleSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;
