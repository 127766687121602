import styled, { css } from 'styled-components';
import Image from 'next/legacy/image';

import { StaggerElements, InView, STAGGER_DELAY } from '@yoweb/ui/components/AnimateIn';
import { Title, Text, textBase } from '@yoweb/ui/components/typography';
import { textSizeBodyMixin } from '@yoweb/ui/components/typography/Text';
import { getColor, getFontSize, getSpace } from '@yoweb/ui/lib/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/lib/styles/utils';

export type WhatWeDoItemProps = {
  src: string;
  title: string;
  text: string | ReactNode;
  url?: string;
  index: number;
  withBorderBottom: boolean;
} & DataTestId;

export const WhatWeDoItem = ({ src, title, text, index, withBorderBottom }: WhatWeDoItemProps) => (
  <InView>
    {(isInView) => (
      <StaggerElements isInView={isInView} initialDelay={STAGGER_DELAY}>
        <ItemGrid
          withBorderBottom={withBorderBottom && index !== 3}
          data-testid={`what-we-do-item-${index}`}
        >
          <ImageWrap>
            <Image
              src={src}
              layout="responsive"
              width="240"
              height="240"
              data-testid={`what-we-do-image-${index}`}
            />
          </ImageWrap>
          <TextGrid>
            <StyledTitle size="md" data-testid={`what-we-do-title-${index}`}>
              {title}
            </StyledTitle>
            {typeof text === 'string' && (
              <StyledText
                size={{ _: 'lg', lg: 'xl' }}
                variant="muted"
                data-testid={`what-we-do-text-${index}`}
                whiteSpace="pre-wrap"
              >
                {text}
              </StyledText>
            )}
            {typeof text !== 'string' && (
              <TextWrapper data-testid={`what-we-do-text-${index}`}>{text}</TextWrapper>
            )}
          </TextGrid>
        </ItemGrid>
      </StaggerElements>
    )}
  </InView>
);

const ItemGrid = styled.li<{ withBorderBottom: boolean }>`
  && {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: ${pxToRem(240)} 1fr;
    grid-gap: ${getSpace('normal2')};
    margin-top: ${getSpace('normal2')};
    ${({ withBorderBottom }) =>
      withBorderBottom &&
      css`
        border-bottom: ${pxToRem(1)} solid ${getColor('base200')};
      `}
    ${media.md`
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: none;
      grid-gap: ${pxToRem(24)};
      padding-bottom: ${getSpace('normal2')};
      margin-top: ${getSpace('medium1')};
    `}

    ${media.lg`
      margin-top: 0;
      align-items: center;
   `}
  }
`;

const ImageWrap = styled.div`
  && {
    width: ${pxToRem(240)};
    grid-column-start: 3;

    ${media.md`
      grid-column-start: 1;
      grid-column-end: 5;
      max-width: ${pxToRem(240)};
      padding: 0;
    `}

    ${media.lg`
      grid-column-start: 2;
    `}
  }
`;

const TextGrid = styled.div`
  && {
    grid-column: 1 / 6;
    display: grid;
    grid-gap: ${getSpace('normal2')};
    padding-bottom: ${getSpace('medium3')};

    ${media.md`
      grid-column-start: 5;
      grid-column-end: 13;
      grid-template-rows: repeat(5, 1fr);
      padding-bottom: 0;
      max-height: ${pxToRem(360)};
    `}

    ${media.lg`
      grid-template-rows: repeat(5, 1fr);
      margin-top: 0;
    `}
  }
`;

const StyledTitle = styled(Title)`
  && {
    grid-row-start: 1;

    ${media.md`
      align-self: end;
    `}

    ${media.lg`
      grid-row-start: 2;
      align-self: start;
    `}
  }
`;

const StyledText = styled(Text)`
  && {
    grid-row-start: 2;
    ${media.md`
      grid-row-start: span 3;
    `}
  }
`;

const TextWrapper = styled.div`
  grid-row-start: 2;
  white-space: pre-wrap;

  a {
    text-decoration: underline;
  }

  && {
    color: ${getColor('charcoal')};
  }

  ${media.md`
    grid-row-start: span 3;
  `}

  ${media.lg`
    font-size: ${getFontSize('bodyXl')}
  `}
  ${textBase}
  ${textSizeBodyMixin}
`;
