import styled from 'styled-components';

import { Box } from '@yoweb/ui/components/Box';
import { pxToRem, media } from '@yoweb/ui/styles/utils';
import { getSpace, getColor, getRadii } from '@yoweb/ui/styles/utils/theme';
import AnimateIn, { InView, DURATIONS } from '@yoweb/ui/components/AnimateIn';
import { Title } from '@yoweb/ui/components/typography';
import { StarIcon } from '@yoweb/ui/components/Icon/StarIcon';
import { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

type ReviewSectionProps = {
  starCount: number;
  review: string;
  author: string;
};

export const ReviewSection = ({ starCount, review, author }: ReviewSectionProps) => {
  const config = {
    opacity: { animate: true, duration: DURATIONS.d67 },
    y: { animate: true, offset: 50, duration: DURATIONS.d33 },
  };
  const stars: JSX.Element[] = [];
  for (let value = 1; value <= starCount; value++) {
    stars.push(<ReviewStar key={value} size={32} />);
  }
  return (
    <ReviewSectionWrapper>
      <ReviewSectionContent>
        <InView>
          {(isInView) =>
            isInView && (
              <Box mt="medium2" position="relative" mb="normal2">
                {stars}
              </Box>
            )
          }
        </InView>
        <InView>
          {(isInView) => (
            <AnimateIn isVisible={isInView} config={config}>
              <Box mb="medium2">
                <Title size="md" textAlign="center" whiteSpace="pre-wrap">
                  {review}
                </Title>
                <Box mt="normal1">
                  <AuthorText>{author}</AuthorText>
                </Box>
              </Box>
            </AnimateIn>
          )}
        </InView>
      </ReviewSectionContent>
    </ReviewSectionWrapper>
  );
};

const ReviewSectionWrapper = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: ${pxToRem(680)};

  ${media.md`
  margin: ${getSpace('normal1')} 0 ${getSpace('normal1')} 0;
  `}
`;

const ReviewSectionContent = styled.div`
  display: grid;
  justify-items: center;
  margin: 0 auto;
  row-gap: ${getSpace('small1')};
  background-color: ${getColor('sand')};
  width: 100%;

  ${media.md`
    margin-left: ${getSpace('normal3')};
    margin-right: ${getSpace('normal3')};
    border-radius: ${getRadii('medium')};
  `}
`;

const ReviewStar = styled(StarIcon)<IconProps>`
  margin: 0 ${pxToRem(2)} 0 ${pxToRem(2)};

  path {
    stroke: unset;
  }
`;

const AuthorText = styled.p`
  font-size: ${pxToRem(24)};
  text-align: center;
`;
