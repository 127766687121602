export const INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER';
export const PASSWORD_STRENGTH_ERROR = 'PasswordStrengthError';
export const PASSWORD_DICTIONARY_ERROR = 'PasswordDictionaryError';
export const AUTH0_SANDBOX_ERROR = 'Sandbox Error';
export const AUTH0_PAYLOAD_VALIDATION_ERROR = 'Payload validation error';
export const INELIGIBLE_ADDRESS = 'InvalidAddress';
export const DEFAULT_CARD_ERROR = 'default_card_error';
export const CARD_DECLINED = 'card_declined';
export const INCORRECT_ZIP = 'incorrect_zip';
export const UNAUTHORIZED = 'Unauthorized';
export const EXISTING_SUBSCRIPTION = 'existing_subscription';
export const INVALID_TOKEN = 'invalid_token';
export const SIGNUP_DISABLED = 'signup_disabled';

// These errors will be suppressed and therefore not sent to sentry.
export const stripeErrorList = [
  CARD_DECLINED,
  INCORRECT_ZIP,
  INELIGIBLE_ADDRESS,
  DEFAULT_CARD_ERROR,
];
