import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const ReviewSectionSliceType = 'review_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['starCount', 'review', 'author'] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  starCount: string;
  review: string;
  author: string;
};

export type ReviewSectionSliceResponse = PrismicSlice<
  typeof ReviewSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
