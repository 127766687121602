import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type WhatWeDoSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './WhatWeDoSectionSliceTypes';
import { WhatWeDoSection } from '@yoweb/ui/components/sections/WhatWeDoSection/WhatWeDoSection';

type WhatWeDoSectionSliceProps = {
  slice: WhatWeDoSectionSliceResponse;
};

const WhatWeDoSectionSlice = ({ slice }: WhatWeDoSectionSliceProps) => {
  const { title, subtitle, image } = slice.primary;

  const props = {
    title,
    subtitle: <PrismicRichText field={subtitle} />,
    imageSrc: image?.url ?? '',
  };

  return <WhatWeDoSection {...props} />;
};

export default withPrismicGuardian(WhatWeDoSectionSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
