import Image from 'next/legacy/image';
import styled from 'styled-components';
import { useState } from 'react';

import { Container } from '@yoweb/ui/components/containers';
import { Grid } from '@yoweb/ui/components/Grid';
import { Box } from '@yoweb/ui/components/Box';
import { StaggerElements, InView, AnimateWhenInView } from '@yoweb/ui/components/AnimateIn';
import { Title } from '@yoweb/ui/components/typography';
import { getSpace, getColor, getRadii } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { PlayIcon } from '@yoweb/ui/components/Icon/PlayIcon';
import { VideoModal } from '@yoweb/ui/components/Media/VideoModal';

type VideoThumbnail = {
  title: string;
  image?: string | null;
  video: string;
};

type VideoThumbnailSectionProps = {
  title?: string;
  subtitle?: ReactNode;
  videoThumbnails: VideoThumbnail[];
};

export const VideoThumbnailSection = ({
  title,
  subtitle,
  videoThumbnails,
}: VideoThumbnailSectionProps) => {
  const [videoId, setVideoId] = useState('');
  return (
    <Container>
      <InView>
        {(isInView) => (
          <Box>
            <Grid gap="none" justify="center">
              <StaggerElements isInView={isInView}>
                {title && (
                  <Title textAlign="center" size={{ _: 'lg', md: 'xl' }}>
                    {title}
                  </Title>
                )}
                <SubtitleWrapper maxWidth={600}>{subtitle}</SubtitleWrapper>
              </StaggerElements>
            </Grid>
            <AnimateWhenInView>
              <Box pt="medium3">
                <Grid gap="normal2" justifyItems="center">
                  <VideoThumbnails data-testid="video-thumbnail-grid">
                    {videoThumbnails.map((thumbnail, index) => (
                      <TitleButton
                        data-testid={`${index}-video-button`}
                        key={thumbnail.title}
                        onClick={() => setVideoId(thumbnail.video)}
                      >
                        <StyledThumbnailGrid as="li">
                          <StyledVideoThumbnail>
                            {thumbnail.image && (
                              <Image src={thumbnail.image} layout="fill" alt={thumbnail.title} />
                            )}
                            <StyledPlayIcon>
                              <PlayIcon width={20} height={20} />
                            </StyledPlayIcon>
                          </StyledVideoThumbnail>
                          <Title size="xs">{thumbnail.title}</Title>
                        </StyledThumbnailGrid>
                      </TitleButton>
                    ))}
                  </VideoThumbnails>
                </Grid>
                {videoId && (
                  <VideoModal
                    testId="press-video-modal"
                    videoUrl={videoId}
                    isOpen={!!videoId}
                    onClose={() => setVideoId('')}
                  />
                )}
              </Box>
            </AnimateWhenInView>
          </Box>
        )}
      </InView>
    </Container>
  );
};

const SubtitleWrapper = styled(Box)`
  && {
    text-align: center;
  }
`;

const VideoThumbnails = styled.ul`
  display: grid;
  row-gap: ${pxToRem(55)};
  width: 100%;

  ${media.lg`
    grid-auto-flow: column;
    column-gap: ${getSpace('normal2')};
    justify-content: center;
  `}
`;

const StyledThumbnailGrid = styled(Grid)`
  && {
    ${media.lg`
      width: ${pxToRem(400)};
    `}
  }
`;

const StyledVideoThumbnail = styled.div`
  align-items: center;
  border-radius: ${getRadii('medium')};
  display: flex;
  grid-column: 1 / span 4;
  justify-content: center;
  height: ${pxToRem(187)};
  overflow: hidden;
  position: relative;

  ${media.md`
    height: ${pxToRem(360)};
  `}
  ${media.lg`
    height: ${pxToRem(224)};
  `}
`;

const TitleButton = styled.button`
  padding: 0;
  margin: 0;
  text-align: left;

  ${media.lg`
    max-width: ${pxToRem(400)};
  `}
`;

const StyledPlayIcon = styled.span`
  align-items: center;
  background: ${getColor('base000')};
  border-radius: 50%;
  display: flex;
  height: 48px;
  justify-content: center;
  pointer-events: none;
  position: relative;
  width: 48px;
`;
