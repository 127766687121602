import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';
import { type HTMLAttributes, useState } from 'react';

import { getColor, getRadii, getZIndex } from '@yoweb/ui/styles/utils/theme';
import { VIDEO_CONTROLS_COLOR } from '@yoweb/ui/constants/media';
import { ButtonIcon } from '../buttons';
import { PlayIcon, WarningIcon } from '../Icon';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import Spinner from '@yoweb/ui/components/Spinner';
import { useTranslation } from '@yoweb/next-i18next';
import { MediaStates, STARTABLE_MEDIA_STATES } from '@yoweb/ui/components/Media/common';

const StyledVideoPlayer = dynamic(() => import('./StyledVideoPlayer'), { ssr: false });

export type VideoPlayerEmbedProps = {
  videoUrl: string;
  autoPlay?: boolean;
  onDuration?: (duration: number) => void;
  testId?: string;
} & HTMLAttributes<HTMLDivElement> &
  DataTestId;

const VideoPlayerEmbed = ({
  videoUrl,
  autoPlay = false,
  onDuration,
  ...rest
}: VideoPlayerEmbedProps) => {
  const { t } = useTranslation<'common'>();
  const [mediaState, setMediaState] = useState(videoUrl ? MediaStates.LOADING : MediaStates.ERROR);

  return (
    <VideoSection {...rest}>
      {mediaState === MediaStates.LOADING && (
        <CenteredContentContainer>
          <Spinner size="lg" />
        </CenteredContentContainer>
      )}
      {mediaState === MediaStates.ERROR && (
        <CenteredContentContainer>
          <ErrorContainer darkMode={!!videoUrl}>
            <WarningIcon variant={videoUrl ? 'warningLight' : 'warning'} size={50} />
            <ErrorTextContainer>
              {t(`error.${videoUrl ? 'media-is-not-available' : 'media-is-not-valid'}`)}
            </ErrorTextContainer>
          </ErrorContainer>
        </CenteredContentContainer>
      )}
      <StyledVideoPlayer
        controls
        mediaState={mediaState}
        playing={mediaState === MediaStates.PLAYING}
        url={videoUrl}
        width="100%"
        height="100%"
        onDuration={onDuration}
        onReady={() => setMediaState(MediaStates.READY)}
        onEnded={() => setMediaState(MediaStates.ENDED)}
        onError={() => setMediaState(MediaStates.ERROR)}
        onPlay={() => setMediaState(MediaStates.PLAYING)}
        onPause={() => setMediaState(MediaStates.PAUSED)}
        config={{
          vimeo: {
            playerOptions: {
              color: VIDEO_CONTROLS_COLOR,
              responsive: true,
              transparent: false,
              autoplay: autoPlay,
            },
          },
        }}
      />
      {STARTABLE_MEDIA_STATES.includes(mediaState) && (
        <CenteredButton>
          <StyledButtonIcon
            onClick={() => setMediaState(MediaStates.PLAYING)}
            icon={<PlayIcon variant="trueBlack" />}
            data-testid="media-hero-video-button"
          />
        </CenteredButton>
      )}
    </VideoSection>
  );
};

const VideoSection = styled.div`
  position: relative;
  border-radius: ${getRadii('large')};
  min-height: ${pxToRem(400)};
  ${media.md`
    min-height: ${pxToRem(450)};
  `}
  ${media.lg`
    min-height: ${pxToRem(500)};
  `}
`;

const CenteredContentContainer = styled.div`
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: ${getZIndex('modal')};
`;

const ErrorContainer = styled.div<{ darkMode?: boolean }>`
  object-position: center;
  text-align: center;
  ${({ darkMode }) => css`
    color: ${getColor(darkMode ? 'warningLight' : 'warning')};
  `}
`;

const ErrorTextContainer = styled.div`
  padding-top: ${pxToRem(20)};
`;

const CenteredButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${getZIndex('modal')};
`;
const StyledButtonIcon = styled(ButtonIcon)`
  && {
    background-color: ${getColor('base050')};

    :hover {
      background-color: ${getColor('successLight')};
    }
  }
`;

export default VideoPlayerEmbed;
