import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  requiredPrimaryFields,
  requiredItemsFields,
} from '@yoweb/prismic-slicemachine/slices/PricingModuleSlice/PricingModuleSliceTypes';
import type {
  PricingModuleSliceResponse,
  UIProps,
} from '@yoweb/prismic-slicemachine/slices/PricingModuleSlice/PricingModuleSliceTypes';
import { PricingModuleSection } from '@yoweb/ui/components/marketing/PricingModuleSection';
import type { PricingProps } from '@yoweb/store/utils/discount';

type PricingModuleSliceProps = {
  slice: PricingModuleSliceResponse;
} & PricingProps &
  UIProps;

const PricingModuleSlice = ({ slice, ...rest }: PricingModuleSliceProps): JSX.Element => {
  const { subtitle, ctaText, ctaLink, bgColor, animation, navId } = slice.primary;
  const { items } = slice;
  const ctaHref = ctaLink.url;

  const bulletPoints = items.map((item) => item.description);

  const props = {
    subtitle,
    ctaText,
    ctaLink: ctaHref,
    bgColor: bgColor ?? '',
    animation: animation ?? '',
    navId,
    bulletPoints,
    ...rest,
  };
  return (
    <div id={navId}>
      <PricingModuleSection {...props} />
    </div>
  );
};

export default withPrismicGuardian(PricingModuleSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});

export type { PricingProps };
