import { useTranslation, Trans, type ParseKeys } from '@yoweb/next-i18next';
import type { PricingModuleSectionProps } from '@yoweb/ui/components/marketing/PricingModuleSection';
import { Text } from '@yoweb/ui/components/typography';

type Values = {
  basePrice: string;
  count?: number;
  discountMonths?: number;
  discountDays?: number;
  discountPrice?: string;
  initialOfferPrice?: string;
  disclaimerPretext?: string;
};

export const PricingDisclaimer = (props: PricingModuleSectionProps) => {
  const { t } = useTranslation('pricing');
  const {
    basePrice,
    useSubscriptionTrialRepeatingUi,
    useSubscriptionTrialOnceUi,
    hasCoupon,
    discountPrice,
    discountMonths,
    initialOfferMonths,
    initialOfferDays,
    initialOfferPriceFormatted,
    disclaimerPretext,
    disclaimerLink,
  } = props;

  const disclaimerPath = 'home-module.disclaimer';
  let disclaimer: ParseKeys<'pricing'> = `${disclaimerPath}.regular`;
  const values: Values = { basePrice };

  if (hasCoupon) {
    values.discountPrice = discountPrice;

    if (useSubscriptionTrialRepeatingUi) {
      disclaimer = `${disclaimerPath}.coupon-combo`;
      values.count = initialOfferMonths;
      values.initialOfferPrice = initialOfferPriceFormatted;
      values.discountMonths = discountMonths;
    } else if (useSubscriptionTrialOnceUi) {
      disclaimer = `${disclaimerPath}.coupon-trial-months`;
      values.count = initialOfferMonths;
      if (initialOfferDays && initialOfferDays > 0) {
        disclaimer = `${disclaimerPath}.coupon-trial-days`;
        values.count = initialOfferDays;
      }
      values.initialOfferPrice = initialOfferPriceFormatted;
      values.disclaimerPretext = disclaimerPretext;
    } else {
      disclaimer = `${disclaimerPath}.coupon`;
      values.count = discountMonths;
    }
  }

  return (
    <Trans
      data-testid="pricing-disclaimer"
      t={t}
      i18nKey={disclaimer}
      values={values}
      components={[
        <Text
          key={0}
          as="a"
          href={disclaimerLink}
          textDecoration="underline"
          target="_blank"
          rel="noreferrer"
        />,
      ]}
    />
  );
};
