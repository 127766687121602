import styled from 'styled-components';
import type { Colors } from 'styled-components';

import { Box } from '@yoweb/ui/components/Box';
import { type LargeVideoSectionSliceResponse } from './LargeVideoSectionSliceTypes';
import { MediaHeroEmbed } from '@yoweb/ui/components/marketing/MediaHeroEmbed';
import AnimateIn, {
  InView,
  STAGGER_DELAY,
  EASINGS,
  DURATIONS,
} from '@yoweb/ui/components/AnimateIn';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { Title, Text } from '@yoweb/ui/components/typography';
import { theme } from '@yoweb/ui/styles';

/**
 * Props for `LargeVideoSectionSlice`.
 */
export type LargeVideoSectionSliceProps = {
  slice: LargeVideoSectionSliceResponse;
} & DataTestId;

const LargeVideoSectionSlice = ({
  slice,
  'data-testid': dataTestId = 'large-video',
}: LargeVideoSectionSliceProps) => {
  const { primary } = slice;

  const {
    title,
    description,
    videoUrl,
    videoMobileUrl,
    backgroundColor = 'sprout',
    navId,
  } = primary;

  const bgColor = theme.colors[backgroundColor as keyof Colors];

  return (
    <div data-testid={`${dataTestId}-section`} id={navId} style={{ backgroundColor: bgColor }}>
      <InView>
        {(isInView) => (
          <StyledMediaSectionContainer>
            <ContentContainer>
              <Title
                data-testid={`${dataTestId}-section-title`}
                size={{ _: 'md', md: 'lg', lg: 'xl' }}
              >
                {title}
              </Title>
              <DescriptionText data-testid={`${dataTestId}-section-text`} size="xl">
                {description}
              </DescriptionText>
            </ContentContainer>
            <VideoSectionGrid data-testid={`${dataTestId}-section-grid`}>
              <VideoContainer>
                <AnimateIn
                  isVisible={isInView}
                  config={{
                    y: { offset: 100, duration: DURATIONS.d67 },
                    opacity: { animate: true, ease: EASINGS.ease33 },
                  }}
                  delay={STAGGER_DELAY + DURATIONS.d67}
                >
                  <MediaHeroEmbed videoUrl={videoUrl.url} videoMobileUrl={videoMobileUrl?.url} />
                </AnimateIn>
              </VideoContainer>
            </VideoSectionGrid>
          </StyledMediaSectionContainer>
        )}
      </InView>
    </div>
  );
};

const StyledMediaSectionContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: ${pxToRem(90)} 0 ${pxToRem(45)} 0;
`;

const VideoSectionGrid = styled(Box)`
  align-items: center;
  row-gap: ${getSpace('medium2')};
`;

const VideoContainer = styled.div`
  grid-column: 1 / span 12;
  display: grid;
  align-items: flex-end;
  row-gap: ${getSpace('normal2')};

  ${media.md`
    grid-column: 1 / span 8;
  `}

  ${media.lg`
    grid-column: 1 / span 9;
  `}
`;

const ContentContainer = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: ${getSpace('normal2')};
`;

const DescriptionText = styled(Text)`
  max-width: ${pxToRem(680)};
  margin: auto;
  margin-top: ${pxToRem(20)};
  margin-bottom: ${pxToRem(40)};
`;

export default LargeVideoSectionSlice;
