import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type ReviewSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './ReviewSectionSliceTypes';
import { ReviewSection } from '@yoweb/ui/components/sections/ReviewSection';

type WebinarSectionSliceProps = {
  slice: ReviewSectionSliceResponse;
};

const WebinarSectionSlice = ({ slice }: WebinarSectionSliceProps) => {
  const { starCount, review, author } = slice.primary;

  const props = {
    starCount: Number(starCount),
    review,
    author,
  };

  return <ReviewSection {...props} />;
};

export default withPrismicGuardian(WebinarSectionSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
