import { PrismicRichText } from '@prismicio/react';
import * as prismicHelpers from '@prismicio/helpers';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type VideoThumbnailsSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './VideoThumbnailsSliceTypes';
import { VideoThumbnailSection } from '@yoweb/ui/components/sections/VideoThumbnailSection';
import { paragraphBodyLinkSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';

type VideoThumbnailsSliceProps = {
  slice: VideoThumbnailsSliceResponse;
};

const VideoThumbnailsSlice = ({ slice }: VideoThumbnailsSliceProps) => {
  const { title, subtitle } = slice.primary;

  const props = {
    title: title ? prismicHelpers.asText(title) : '',
    subtitle: <PrismicRichText field={subtitle} components={paragraphBodyLinkSerializer} />,
    videoThumbnails: slice.items.map(({ videoTitle, thumbnail, videoUrl }) => ({
      title: videoTitle,
      image: thumbnail.url,
      video: videoUrl.url,
    })),
  };

  return <VideoThumbnailSection {...props} />;
};

export default withPrismicGuardian(VideoThumbnailsSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
