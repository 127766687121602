import styled from 'styled-components';
import Link from 'next/link';
import Image from 'next/legacy/image';

import { Box } from '@yoweb/ui/components/Box';
import { pxToRem, media } from '@yoweb/ui/styles/utils';
import { getSpace, getColor } from '@yoweb/ui/styles/utils/theme';
import AnimateIn, { InView, DURATIONS } from '@yoweb/ui/components/AnimateIn';
import { Title } from '@yoweb/ui/components/typography';
import { Button, type ButtonProps } from '@yoweb/ui/components/buttons/Button';

type WebinarSectionProps = {
  title: string;
  subtitle?: string;
  ctaHref: string;
  ctaLabel: string;
};

export const WebinarSection = ({ title, subtitle, ctaHref, ctaLabel }: WebinarSectionProps) => {
  const config = {
    opacity: { animate: true, duration: DURATIONS.d67 },
    y: { animate: true, offset: 50, duration: DURATIONS.d33 },
  };
  return (
    <WebinarSectionWrapper>
      <WebinarSectionContent>
        <InView>
          {(isInView) =>
            isInView && (
              <Box
                height={{ _: 150 }}
                width={{ _: 150 }}
                mt={'large1'}
                position="relative"
                mb={{ _: 'normal2' }}
              >
                <Image src="/images/illustrations/webinar.svg" layout="fill" />
              </Box>
            )
          }
        </InView>
        <InView>
          {(isInView) => (
            <AnimateIn isVisible={isInView} config={config}>
              <Box mb={'normal3'}>
                <Title textAlign="center" whiteSpace="pre-wrap">
                  {title}
                </Title>
              </Box>
            </AnimateIn>
          )}
        </InView>

        {subtitle && (
          <InView>
            {(isInView) => (
              <AnimateIn isVisible={isInView} config={config}>
                <Box maxWidth={576} mb={'normal3'}>
                  <Subtitle>{subtitle}</Subtitle>
                </Box>
              </AnimateIn>
            )}
          </InView>
        )}

        {ctaHref && (
          <InView>
            {(isInView) => (
              <AnimateIn isVisible={isInView} config={config}>
                <Box mt={{ _: 'normal3', md: 'normal2' }} mb={'xl1'}>
                  {ctaHref && (
                    <Link href={ctaHref} passHref legacyBehavior>
                      <CtaButton
                        variant="secondary"
                        href={ctaHref}
                        data-testid="webinarCta-button"
                        as="a"
                        size="lg"
                      >
                        {ctaLabel}
                      </CtaButton>
                    </Link>
                  )}
                </Box>
              </AnimateIn>
            )}
          </InView>
        )}
      </WebinarSectionContent>
    </WebinarSectionWrapper>
  );
};

const WebinarSectionWrapper = styled(Box)`
  && {
    align-items: center;
    display: flex;
    justify-content: center;
    max-height: ${pxToRem(680)};
    padding-left: ${getSpace('normal2')};
    padding-right: ${getSpace('normal2')};
    background-color: ${getColor('charcoal')};
    color: ${getColor('base000')};
  }
`;

const WebinarSectionContent = styled.div`
  display: grid;
  justify-items: center;
  margin: 0 auto;
  row-gap: ${getSpace('small1')};

  ${media.md`
    max-width: ${pxToRem(563)};
  `}

  ${media.lg`
    max-width: ${pxToRem(808)};
  `}
`;

const CtaButton = styled(Button)<ButtonProps>`
  padding: 1rem;
  border-radius: ${pxToRem(28)};
  border: 1.1px solid ${getColor('base100')};
  font-weight: 700;
`;

const Subtitle = styled.p`
  font-size: ${pxToRem(24)};
  font-weight: 400;
  line-height: 32px;
  text-align: center;
`;
