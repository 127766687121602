import type { ReactNode } from 'react';
import styled from 'styled-components';

import { getRadii, getSize, getSpace, getZIndex } from '@yoweb/ui/styles/utils/theme';
import { useMobileOnly } from '@yoweb/ui/components/Responsive';
import VideoPlayerEmbed from '@yoweb/ui/components/Media/VideoPlayerEmbed';

export type MediaHeroEmbedProps = {
  videoUrl?: string;
  videoMobileUrl?: string;
  onDuration?: (duration: number) => void;
  children?: ReactNode;
};

/**
 * Component that renders full width image in 3:2 aspect ratio and has an optional video that opens up in a modal
 */
export const MediaHeroEmbed = ({
  videoUrl = '',
  videoMobileUrl = '',
  onDuration,
}: MediaHeroEmbedProps) => {
  const isMobile = useMobileOnly();

  const video = isMobile ? videoMobileUrl || videoUrl : videoUrl || videoMobileUrl;

  return (
    <StyledContainer data-testid="media-hero-embed">
      <BackgroundImage data-testid="media-hero-embed-image">
        <VideoPlayerEmbed videoUrl={video} onDuration={onDuration} />
      </BackgroundImage>
    </StyledContainer>
  );
};

const StyledContainer = styled.section`
  border-radius: ${getRadii('medium')};
  z-index: ${getZIndex('layer')};
  max-width: calc(${getSize('containerWidth')} + 2 * ${getSpace('normal2')});
  margin: 0 auto;
`;

const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
`;
