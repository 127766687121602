import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const StarIcon = (props: IconProps) => (
  <IconBase fill="none" viewBox="0 0 47 47" {...props}>
    <path
      d="M22.5853 1.97697C22.8397 1.1553 23.9475 1.1553 24.2019 1.97697L28.8205 16.8932C28.9343 17.2606 29.2606 17.5094 29.6288 17.5094H44.5749C45.3982 17.5094 45.7405 18.615 45.0744 19.1228L32.9828 28.3415C32.6849 28.5686 32.5603 28.9711 32.6741 29.3386L37.2927 44.2548C37.5471 45.0765 36.6509 45.7597 35.9848 45.2519L23.8932 36.0332C23.5953 35.8061 23.1919 35.8061 22.8941 36.0332L10.8024 45.2519C10.1364 45.7597 9.24017 45.0765 9.49459 44.2548L14.1132 29.3386C14.227 28.9711 14.1023 28.5686 13.8044 28.3415L1.71281 19.1228C1.04673 18.615 1.38905 17.5094 2.21236 17.5094H17.1584C17.5266 17.5094 17.853 17.2606 17.9667 16.8932L22.5853 1.97697Z"
      stroke="#919097"
      strokeWidth="1.75"
      fill="currentColor"
    />
  </IconBase>
);
