import type { FilledLinkToWebField, ImageField, RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const VideoThumbnailsSliceType = 'video_thumbnails_slice';

// Required fields in slice
export const requiredPrimaryFields = [] as const; // Array of all required primary fields.
export const requiredItemsFields = ['videoTitle', 'thumbnail', 'videoUrl'] as const; // Array of all required items fields.

type Primary = {
  title?: RichTextField;
  subtitle?: RichTextField;
};

type Item = {
  videoTitle: string;
  thumbnail: ImageField;
  videoUrl: FilledLinkToWebField;
};

export type VideoThumbnailsSliceResponse = PrismicSlice<
  typeof VideoThumbnailsSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>,
  'default'
>;
