import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const InfoIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.747 11.996a8.25 8.25 0 1 1 16.5 0 8.25 8.25 0 0 1-16.5 0Zm8.25-9.75c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75c5.384 0 9.75-4.365 9.75-9.75s-4.366-9.75-9.75-9.75Zm-.998 12.505a.75.75 0 0 0 0 1.5h2.311a.75.75 0 1 0 0-1.5h-.4V11.25a.75.75 0 0 0-.75-.75h-1.151a.75.75 0 0 0 0 1.5h.4v2.751H11Zm.851-5.501a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="currentColor"
    />
  </IconBase>
);

export default InfoIcon;
