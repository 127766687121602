import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type WebinarSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './WebinarSectionSliceTypes';
import { WebinarSection } from '@yoweb/ui/components/sections/WebinarSection';

type WebinarSectionSliceProps = {
  slice: WebinarSectionSliceResponse;
};

const WebinarSectionSlice = ({ slice }: WebinarSectionSliceProps) => {
  const { ctaHref, ctaLabel, title, subtitle } = slice.primary;

  const props = {
    ctaHref,
    ctaLabel,
    title,
    subtitle,
  };

  return <WebinarSection {...props} />;
};

export default withPrismicGuardian(WebinarSectionSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
